import React from "react";
import "./Proimg.css";
import CardSlider from "./ProimgMobile/CardSlider";

const ImageLayout = () => {
  return (
    <>
      {/* <div className="container d-none d-md-block mb-5">
        <div className="row">
          <h1 className="text-center">Our Project</h1>
          <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12">
            <div className="h-50 mb-1">
              <img
                src="https://agepcoatings.com/Uploads/Proslide_Card/01.png"
                alt="1"
                className="img-fluid h-100"
              />
              <div className="overlay">
                <div className="text">Cement</div>
              </div>
            </div>
            <div>
              <img
                src="https://agepcoatings.com/Uploads/Proslide_Card/02.png"
                alt="2"
                className="img-fluid h-100"
              />
              <div className="overlay">
                <div className="text">Cement</div>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12">
            <div>
              <img
                src="https://agepcoatings.com/Uploads/Proslide_Card/03.png"
                alt="3"
                className="img-fluid h-100"
              />
               <div className="overlay">
                <div className="text">Cement</div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-6 img-container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <img
                  src="https://agepcoatings.com/Uploads/Proslide_Card/04.png"
                  alt="4"
                  className="img-fluid h-100"
                />
                 <div className="overlay">
                <div className="text">Cement</div>
              </div>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <img
                  src="https://agepcoatings.com/Uploads/Proslide_Card/05.png"
                  alt="5"
                  className="img-fluid h-100"
                />
                 <div className="overlay">
                <div className="text">Cement</div>
              </div>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <img
                  src="http://localhost:3008/Uploads/Proslide_Card/06.png"
                  alt="6"
                  className="img-fluid h-100"
                />
                 <div className="overlay">
                <div className="text">Cement</div>
              </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <img
                  src="http://localhost:3008/Uploads/Proslide_Card/07.png"
                  alt="7"
                  className="img-fluid h-100"
                />
                 <div className="overlay">
                <div className="text">Cement</div>
              </div>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12">
            <div>
              <img
                src="http://localhost:3008/Uploads/Proslide_Card/08.png"
                alt="8"
                className="img-fluid full-height"
              />
               <div className="overlay">
                <div className="text">Cement</div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container d-none d-md-block mb-5">
        <div className="row">
          <h1 className="text-center">Our Project</h1>
          <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12">
            <div className="h-50 mb-0 img-container">
              <img
                src="https://agepcoatings.com/Uploads/Proslide_Card/01.png"
                alt="1"
                className="img-fluid h-100"
              />
              <div className="overlay">
                <div className="text">MARINE</div>
              </div>
            </div>
            <div className="img-container">
              <img
                src="https://agepcoatings.com/Uploads/Proslide_Card/02.png"
                alt="2"
                className="img-fluid h-100"
              />
              <div className="overlay">
                <div className="text">OIL & GAS</div>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12">
            <div className="img-container">
              <img
                src="https://agepcoatings.com/Uploads/Proslide_Card/03.png"
                alt="3"
                className="img-fluid h-100"
              />
              <div className="overlay">
                <div className="text">CHEMICAL PROCESS</div>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-6">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 img-container mb-0">
                <img
                  src="https://agepcoatings.com/Uploads/Proslide_Card/04.png"
                  alt="4"
                  className="img-fluid h-100"
                />
                <div className="overlay">
                  <div className="text">CEMENT</div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 img-container mb-0">
                <img
                  src="https://agepcoatings.com/Uploads/Proslide_Card/05.png"
                  alt="5"
                  className="img-fluid h-100"
                />
                <div className="overlay">
                  <div className="text">HEAVY ENGINEERING</div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 img-container">
                <img
                  src="https://agepcoatings.com/Uploads/Proslide_Card/06.png"
                  alt="6"
                  className="img-fluid h-100"
                />
                <div className="overlay">
                  <div className="text">COMMERCIAL SPACES</div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 img-container">
                <img
                  src="https://agepcoatings.com/Uploads/Proslide_Card/07.png"
                  alt="7"
                  className="img-fluid h-100"
                />
                <div className="overlay">
                  <div className="text">STEEL</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12">
            <div className="img-container">
              <img
                src="https://agepcoatings.com/Uploads/Proslide_Card/08.png"
                alt="8"
                className="img-fluid full-height"
              />
              <div className="overlay">
                <div className="text">POWER</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CardSlider />
    </>
  );
};

export default ImageLayout;
